import React from 'react'
import './LatestNews.css'
import topCorner from "../../Assets/diffCardTopCorner.svg"
import bottomCorner from "../../Assets/diffCardBottomCorner.svg"
import mainbottomCorner from "../../Assets/diffcardmainbottomcorner.svg"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons/faArrowRight'

const LatestNews = () => {
  return (
    <div className='makenews'>
    <div className='textBoxnews'>
        <div className='titlenews'>Latest News</div>
        <div className='titledescription'>From an early stage-up’s growth strategies to helping existing businesses, 
        we have done it all! The results speak for themselves. Our service work.</div>
    </div>
    <div className="flex flex-col md:flex-row py-12 items-start gap-6">
        <div className='newsCard'>
            <div className='newsCardImg' style={{ backgroundImage: `url(${require('../../Assets/diffcard1.png')})` }}>
                <div className='newsCardBack'>
                    <div className='newsCardTitle'>Kawaii Group-Nakanishi Sangyo Co. Ltd. MOU Signing Ceremony</div>
                </div>
                <div className='newsCardCornerBg'>
                    <img src={topCorner} alt="" />
                </div>
            </div>
            <div className='newsCardContentBox'>
                <div className='newsCardContentDescription'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent finibus leo at pretium ultrices. In id lorem non mi pretium sagittis. Curabitur non maximus mauris, non malesuada nibh. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus maximus, lacus eu mattis congue, leo mi volutpat odio, non lacinia dui ligula id mi. Proin pretium mi et sapien bibendum ullamcorper. Praesent at libero cursus, bibendum diam et, ullamcorper nulla. Duis sed turpis pellentesque, congue tellus nec, euismod risus. Nunc arcu lectus, laoreet ac maximus nec, scelerisque non lorem. Proin et ipsum aliquet, eleifend urna a, fermentum diam. Morbi convallis mi non enim mollis imperdiet. Nullam eget est ornare, tincidunt tellus eu, placerat ligula. Suspendisse sit amet turpis pharetra, aliquet enim at, elementum felis. Donec imperdiet scelerisque facilisis. Maecenas convallis purus a euismod luctus. Donec fermentum, nulla a efficitur luctus, odio lectus dictum quam, id convallis erat nulla eget magna.</div>
           <div className='newsCardBottom'>
            <div className='newsDate'>12 APRIL 2024</div>
            <div className='newsReadMore'>
                <div className='newsReadMoreText'>READ more</div>
                <FontAwesomeIcon icon={faArrowRight} className='readMoreArrow'/>
            </div>
           </div>
            </div>
            <div className='newsCardCornerDownBg'>
                <img src={bottomCorner} alt="" />
            </div>
        </div>
        <div className='newsCard'>
            <div className='newsCardImg' style={{ backgroundImage: `url(${require('../../Assets/diffcard1.png')})` }}>
                <div className='newsCardBack'>
                    <div className='newsCardTitle'>Kawaii Group-Nakanishi Sangyo Co. Ltd. MOU Signing Ceremony</div>
                </div>
                <div className='newsCardCornerBg'>
                    <img src={topCorner} alt="" />
                </div>
            </div>
            <div className='newsCardContentBox'>
                <div className='newsCardContentDescription'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent finibus leo at pretium ultrices. In id lorem non mi pretium sagittis. Curabitur non maximus mauris, non malesuada nibh. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus maximus, lacus eu mattis congue, leo mi volutpat odio, non lacinia dui ligula id mi. Proin pretium mi et sapien bibendum ullamcorper. Praesent at libero cursus, bibendum diam et, ullamcorper nulla. Duis sed turpis pellentesque, congue tellus nec, euismod risus. Nunc arcu lectus, laoreet ac maximus nec, scelerisque non lorem. Proin et ipsum aliquet, eleifend urna a, fermentum diam. Morbi convallis mi non enim mollis imperdiet. Nullam eget est ornare, tincidunt tellus eu, placerat ligula. Suspendisse sit amet turpis pharetra, aliquet enim at, elementum felis. Donec imperdiet scelerisque facilisis. Maecenas convallis purus a euismod luctus. Donec fermentum, nulla a efficitur luctus, odio lectus dictum quam, id convallis erat nulla eget magna.</div>
           <div className='newsCardBottom'>
            <div className='newsDate'>12 APRIL 2024</div>
            <div className='newsReadMore'>
                <div className='newsReadMoreText'>READ more</div>
                <FontAwesomeIcon icon={faArrowRight} className='readMoreArrow'/>
            </div>
           </div>
            </div>
            <div className='newsCardCornerDownBg'>
                <img src={bottomCorner} alt="" />
            </div>
        </div>
        <div className='newsCard'>
            <div className='newsCardImg' style={{ backgroundImage: `url(${require('../../Assets/diffcard1.png')})` }}>
                <div className='newsCardBack'>
                    <div className='newsCardTitle'>Kawaii Group-Nakanishi Sangyo Co. Ltd. MOU Signing Ceremony</div>
                </div>
                <div className='newsCardCornerBg'>
                    <img src={topCorner} alt="" />
                </div>
            </div>
            <div className='newsCardContentBox'>
                <div className='newsCardContentDescription'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent finibus leo at pretium ultrices. In id lorem non mi pretium sagittis. Curabitur non maximus mauris, non malesuada nibh. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus maximus, lacus eu mattis congue, leo mi volutpat odio, non lacinia dui ligula id mi. Proin pretium mi et sapien bibendum ullamcorper. Praesent at libero cursus, bibendum diam et, ullamcorper nulla. Duis sed turpis pellentesque, congue tellus nec, euismod risus. Nunc arcu lectus, laoreet ac maximus nec, scelerisque non lorem. Proin et ipsum aliquet, eleifend urna a, fermentum diam. Morbi convallis mi non enim mollis imperdiet. Nullam eget est ornare, tincidunt tellus eu, placerat ligula. Suspendisse sit amet turpis pharetra, aliquet enim at, elementum felis. Donec imperdiet scelerisque facilisis. Maecenas convallis purus a euismod luctus. Donec fermentum, nulla a efficitur luctus, odio lectus dictum quam, id convallis erat nulla eget magna.</div>
           <div className='newsCardBottom'>
            <div className='newsDate'>12 APRIL 2024</div>
            <div className='newsReadMore'>
                <div className='newsReadMoreText'>READ more</div>
                <FontAwesomeIcon icon={faArrowRight} className='readMoreArrow'/>
            </div>
           </div>
            </div>
            <div className='newsCardCornerDownBg'>
                <img src={bottomCorner} alt="" />
            </div>
        </div>
    </div>
    <div className='newsCardMainBottomCorner'>
        <img src={mainbottomCorner} alt="" />
    </div>
</div>
  )
}

export default LatestNews

import React from 'react'
import Hero from '../../Components/Hero/Hero'
import Cta from '../../Components/CTA/Cta'
import Map from '../../Components/Map/Map'
import LatestNews from '../../Components/LatestNews/LatestNews'
import CeoMessage from '../../Components/CeoMessage/CeoMessage'
import HowCanWeHelp from '../../Components/HowCanWeHelp/HowCanWeHelp'

const Home = () => {
  return (
    <div>
      <Hero />
      <CeoMessage />
      <HowCanWeHelp />
      <LatestNews />
      <Map />
      <Cta title="Looking for a Trusted Partner
              in Global Business?" text="From recruitment to technology, we've got you covered."
        backgroundImage={require('../../Assets/cta.png')} />
    </div>
  )
}

export default Home

import React from 'react'
import HeaderContact from '../../Components/HeaderContact/HeaderContact'

const Services = () => {
  return (
    <>
    <HeaderContact text="Specified Skilled Worker (SSW)"
    backgroundImage={require('../../Assets/sswCover.png')}/>
    </>
  )
}

export default Services

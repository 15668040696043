import React from 'react'
import "./AboutFirst.css"
import aboutSection1 from "../../Assets/aboutFirst1.jpeg"
import aboutSection2 from "../../Assets/aboutFirst2.jpeg"
import aboutSection3 from "../../Assets/aboutSection3.jpg"


const AboutFirst = () => {
    return (
        <div className='flex flex-col items-center gap-6 self-stretch bg-white md:px-60 md:py-40 px-0 py-6'>
            <div className='flex flex-col md:flex-row justify-center items-center gap-8 self-stretch'>
                <div className="grid grid-cols-2 gap-8 order-2 md:order-1 p-6 md:p-0">
                    <div className="w-58 md:w-64 h-[222.5px] relative overflow-hidden rounded-xl group">
                        <img
                            alt="gallery"
                            className="block h-full w-full object-cover object-center transition-transform duration-300 group-hover:scale-110"
                            src={aboutSection1} />
                            <div className="absolute inset-0 bg-black opacity-40"></div>
                    </div>
                    <div className="row-span-2 w-58 md:w-64 h-[477px] relative overflow-hidden rounded-xl group">
                        <img
                            alt="gallery"
                            className="block h-full w-full object-cover object-center transition-transform duration-300 group-hover:scale-110"
                            src={aboutSection3}
                        />
                        <div className="absolute inset-0 bg-black opacity-40"></div>
                    </div>
                    <div className="w-58 md:w-64 h-[222.5px] relative overflow-hidden rounded-xl group">
                        <img
                            alt="gallery"
                            className="block h-full w-full object-cover object-center transition-transform duration-300 group-hover:scale-110"
                            src={aboutSection2} />
                            <div className="absolute inset-0 bg-black opacity-40"></div>
                    </div>
                </div>
                <div className='flex flex-col justify-center items-start px-8 gap-9 flex-1 self-stretch order-1 md:order-2'>
                    <div className='aboutFirstTitle'>Shaping Tomorrow,
                        Today: The Kawaii Group Story</div>
                    <div className='aboutFirstDes'>Kawaii Group was founded with one core idea: to create opportunities. Whether it's helping businesses thrive or guiding individuals to new career paths, we are passionate about making a difference. With roots deep in both Japan and Bangladesh, our journey over the past 37 years has been about fostering connections—between countries, businesses, and people. Through innovation, education, and strategic partnerships, we aim to uplift communities and empower futures.
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutFirst

import React from 'react'
import './KawaiiConcerns.css'
import KCExplore from "../../Assets/KCExploreDirection.svg"
import hwch_1 from "../../Assets/hwch_1.png"
import hwch_2 from "../../Assets/AjIconic.jpeg"
import hwch_3 from "../../Assets/SaaSKats.png"
import hwch_4 from "../../Assets/KCCardImg/kflCard.jpeg"
import hwch_5 from "../../Assets/KCCardImg/kecCard.png"
import hwch_6 from "../../Assets/KCCardImg/kgjCard.png"
import hwch_7 from "../../Assets/KCCardImg/kddlCard.png"
import hwch_8 from "../../Assets/KCCardImg/kielCard.png"
import hwch_9 from "../../Assets/KCCardImg/kgcCard.png"
import sanajanaLogo from "../../Assets/Sister_Concerns/sanjana_logo.png"
import ajLogo from "../../Assets/Sister_Concerns/aj_logo.png"
import kastlLogo from "../../Assets/Sister_Concerns/KATSL_Logo.png"
import kflLogo from "../../Assets/Sister_Concerns/KF_Logo_Final_1_trans.png"
import keclLogo from "../../Assets/Sister_Concerns/2_logo.png"
import kgjLogo from "../../Assets/Sister_Concerns/3_logo.png"
import kddlLogo from "../../Assets/Sister_Concerns/4_logo.png"
import kiecLogo from "../../Assets/Sister_Concerns/5_logo.png"
import kgcLogo from "../../Assets/Sister_Concerns/7_logo.png"

const concernCards = [
    {
        cardId: 'sanjanaHr',
        backgroundColor: '#FEDFEA',
        concernName: 'M/S Sanjana International',
        concernContent: 'Dedicated recruiting agency.',
        contentDescription: 'We specialize in connecting skilled Bangladeshi professionals with opportunities in Japan and beyond.',
        cardImg: hwch_1,
        cardLogo: sanajanaLogo
    },
    {
        cardId: 'aj',
        backgroundColor: '#FBEACC',
        concernName: 'Achieve Japan',
        concernContent: 'Japanese language learning center.',
        contentDescription: 'We provide top-notch language and cultural training, preparing individuals for success in Japanese business environments.',
        cardImg: hwch_2,
        cardLogo: ajLogo
    },
    {
        cardId: 'katsl',
        backgroundColor: '#D8FCDB',
        concernName: 'Kawaii Advanced Technology & Solution',
        concernContent: 'SaaS (Software as a Service) solution-based software company.',
        contentDescription: 'We develop advanced software solutions to meet the evolving needs of businesses in both Bangladesh and Japan.',
        cardImg: hwch_3,
        cardLogo: kastlLogo
    },
    {
        cardId: 'kfl',
        backgroundColor: '#D8FCDB',
        concernName: 'Kawaii Fashion',
        concernContent: 'Fashion sourcing company. ',
        contentDescription: 'We provide comprehensive services including fashion brand establishment in Japan, OEM, ODM, wholesale, and e-commerce solutions, all tailored to meet the exact requirements of the Japanese market.',
        cardImg: hwch_4,
        cardLogo: kflLogo
    }
    ,
    {
        cardId: 'kecl',
        backgroundColor: '#ACCAFF',
        concernName: 'Kawaii Engineering & Construction',
        concernContent: 'Construction focused company',
        contentDescription: 'This division brings Japanese construction standards to Bangladesh. We undertake projects with a focus on quality, efficiency, and innovation.',
        cardImg: hwch_5,
        cardLogo: keclLogo
    } ,
    {
        cardId: 'kgj',
        backgroundColor: '#DCC1F8',
        concernName: 'Kawaii Group Japan',
        concernContent: 'Our Japanese headquarter.',
        contentDescription: 'It serves as a vital link between Bangladesh and Japan. It facilitates smooth business operations and cultural exchange.',
        cardImg: hwch_6,
        cardLogo: kgjLogo
    },
    {
        cardId: 'kddl',
        backgroundColor: '#FEDFEA',
        concernName: 'Japan Kawaii Design & Development',
        concernContent: 'Business Design & Development focused',
        contentDescription: 'This sister concern combines Japanese design principles with global trends. We offer innovative design solutions for various industries.',
        cardImg: hwch_7,
        cardLogo: kddlLogo
    },
    {
        cardId: 'kiec',
        backgroundColor: '#D8FCDB',
        concernName: 'Kawaii International Education Center',
        concernContent: 'Higher Studies and International Education consultancy agency.',
        contentDescription: 'We assist students in pursuing international education opportunities, with a focus on countries like Europe, and Australia.',
        cardImg: hwch_8,
        cardLogo: kiecLogo
    }
    ,
    {
        cardId: 'kgc',
        backgroundColor: '#FBEACC',
        concernName: 'Kawaii Group Canada',
        concernContent: 'Our Canada focused company',
        contentDescription: 'This branch explores business opportunities and partnerships in the Canadian market.',
        cardImg: hwch_9,
        cardLogo: kgcLogo
    }
];

const KawaiiConcerns = () => {
    return (
        <div className='KawaiiConcernsMain'>
            <div className='KCHeadBox'>
                <div className='KCHeadTitle'>Kawaii Group Family At a Glance</div>
                <div className='KDHeadContent'>
                    At Kawaii Group, we're more than just a company - we're a family of diverse businesses united by a common goal: <span style={{ fontWeight: '700' }}>to bridge gaps between cultures and create value across multiple sectors.</span> Our sister concerns span recruiting, education, technology, fashion, and more, each playing a crucial role in our mission to connect Bangladesh with Japan and the world.
                </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                {concernCards.map((card) => (
                    <div
                        key={card.cardId}
                        className="KCCardBox"
                        onClick={() => document.getElementById(card.cardId).scrollIntoView({ behavior: 'smooth' })}
                        style={{ cursor: 'pointer' }}
                    >
                        <div className='KCRect' style={{ background: card.backgroundColor }}></div>
                        <div className='KCCardContentBox'>
                            <div className='KCCardContentHeader'>{card.concernName}</div>
                            <div className='KCCardContentType'>{card.concernContent}</div>
                        </div>
                        <div className='KCCardDescriptionBox'>
                            <div className='KCCardDescription'>
                                {card.contentDescription}
                            </div>
                        </div>
                        <div className='KCExploreDiv'>
                            <div className='KCExploreBtn'>
                                <div className='KCExploreContent'>Explore Now</div>
                                <img src={KCExplore} alt="KCExplore" />
                            </div>
                        </div>
                        <div className='KCImageBox'>
                            <img src={card.cardImg} alt={`${card.concernName} background`} className='KCCardBg' />
                            <div className="KCimage-overlay"></div>
                            <div className='KCLogoContainer'>
                                <img src={card.cardLogo} alt={`${card.concernName} logo`} className='KCCardLogo' />
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <div className='hidden'>
            <div id="sanjanaHr">
                sanjana hr
            </div>
            <div id="aj">
                Achieve Japan
            </div>
            <div id="katsl">
                Kawaii Advanced Technology & Solutions Ltd
            </div>
            <div id="kfl">
                Kawaii Fashion
            </div>
            <div id="kecl">
                Kawaii Engineering & Construction
            </div>
            <div id="kgj">
                Kawaii group japan
            </div>
            <div id="kddl">
                Kawaii Design & Development
            </div>
            <div id="kiec">
                Kawaii international education
            </div>
            <div id="kgc">
                Kawaii Group Canada
            </div>
            </div>
        </div>
    )
}

export default KawaiiConcerns
